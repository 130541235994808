import { FC } from 'react';

import { useLinkComponent } from '@link';
import { FooterMenuRendering } from '@sitecore/types/manual/Footer';
import { Stack, Text, TextLink } from '@sparky';

const SubFooterMenu: FC<{ fields: FooterMenuRendering['fields'] }> = ({ fields }) => {
  const Link = useLinkComponent();

  if (!fields.items.length) return null;

  return (
    <Stack gap="2">
      {fields.title?.value && (
        <Stack.Item>
          <Text color="textPrimary" weight="bold">
            {fields.title.value}
          </Text>
        </Stack.Item>
      )}
      <Stack.Item>
        <Stack as="ul" direction={{ initial: 'column', md: 'row' }} gap="3" wrap="true">
          {fields.items.map(navItem => {
            const link = navItem?.fields?.link?.value;
            if (!link?.href) return null;
            return (
              <Stack.Item as="li" key={navItem.id}>
                <Link linkType={link.linktype} linkValue={link}>
                  <TextLink color="textPrimary" target={link.target}>
                    {link.text || navItem.displayName}
                  </TextLink>
                </Link>
              </Stack.Item>
            );
          })}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default SubFooterMenu;
